/* @font-face {
  font-family: "Rubik";
  src: url("./../public/assets/fonts/en/Rubik-Regular.woff") format('woff');
}

@font-face {
  font-family: "Rubik-Semi-Bold";
  src: url("./../public/assets/fonts/en/Rubik-SemiBold.woff") format('woff');
  font-weight: 600;
}

@font-face {
  font-family: "Rubik-Bold";
  src: url("./../public/assets/fonts/en/Rubik-Bold.woff") format('woff');
  font-weight: bold;
} */
@media only screen and (max-width: 480px) {
  .regsiter-form {
    padding: 2rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .regsiter-form {
    padding: 2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .regsiter-form {
    padding: 2rem;
  }
}

@media only screen and (min-width: 959px) {
  .regsiter-form {
    padding: 4rem;
    margin: 4rem;
  }
}

a,
div,
span,
h1,
h2,
h3,
h4,
label,
h5,
h6,
button {
  font-family: "Noto Kufi Arabic", sans-serif;
}

body {
  overflow-y: scroll !important;
}

.title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 700;
}

.page-title {
  font-size: 36pt;
  font-weight: bold;
}


.vh-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-group label.required:after {
  content: "*";
  color: red;
}


.glass-card {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.glass-card-min {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 4px 10px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.glass-card-no-radius {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 8px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.login_form_container {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 50px 40px 20px 40px;
}

.login_form_logo_container {
  box-sizing: border-box;
  text-align: center;
}

.login_form_logo_container .logo {
  max-width: 40%;

}

.side-menu {
  background-color: #141B4D;
  position: fixed;
  width: inherit;
  z-index: 10;
  height: 100vh;
  overflow: scroll;
}

.app-sidemenu-bar {
  /* height: 100vh !important; */
  /* overflow-y: scroll !important; */
}

.app-sidemenu-bar li {
  list-style: none !important;
}

.app-sidemenu-bar li span {
  list-style: none;
  display: flex;
  align-items: center;
}

.app-sidemenu-bar li .link-container {
  padding: 1rem;
}

.link-container {
  display: flex;
}

.app-sidemenu-bar li a .material-symbols-outlined {
  font-size: 2rem;
}

.app-sidemenu-bar-item span {
  text-decoration: none;
  color: #FFF;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
}

.app-sidemenu-bar-item a:hover {
  color: #CCC;
}

.app-sidemenu-bar-item img {
  margin-right: 0.5rem;
  height: 32px;
  width: 32px;
}

.app-sidemenu-bar-item.active span {
  background-color: teal;
  color: #FFF;
}

.app-sidemenu-bar-item.active .accordion {
  background-color: teal;
  color: #FFF;
}



.bg-img-vhicle {
  background-image: url('/public/assets/img/vehicle.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFF;
}

.password-toggle {
  position: relative;
  float: right;
  top: -50px;
  right: 10px;
}

.icon-btn {
  padding: 0.5rem !important;
  border-radius: 28px;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-collapse {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.collapsed .accordion-button:after {
  display: none !important;
}

.accordion .accordion-item {
  background-color: inherit !important;
  border-radius: 0px !important;
  border: none !important;
  outline: none !important;
}

.accordion-button {
  background-color: inherit !important;
  outline: none !important;
  margin-left: -5px !important;
  padding: 5px !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-body ul {
  padding-left: 0px !important;

}

.app-sidemenu-bar-item .accordion-body li a {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.app-sidemenu-bar-item .accordion-body li {
  margin-bottom: 0.2rem;
}

.side-menu li a {
  color: #FFF;
  text-decoration: none;
  font-size: 1.2rem;
}

.side-menu li button a {
  font-size: 1.2rem;
  font-weight: bold;
}

.top-header {
  position: fixed;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  box-shadow: 0px 0px 5px 1px #C4C4C4;
  z-index: 5;
  background-color: #FFF;
}

.top-header .header-content {
  display: flex;
  justify-content: space-between;
  padding: 1rem
}

ul.p-contextmenu-root-list {
  padding-left: 0px !important;
}

.app-sidemenu-bar-item .link_title {
  margin-right: 10px;
  margin-left: 10px;
}

.jcsb {
  justify-content: space-between;
  flex-wrap: wrap;
}

.link-btn {
  text-decoration: none;
  background-color: #141B4D;
  color: #FFF;
  border-radius: 3rem;
  padding: 0.65rem 1.25rem;
  font-weight: bold;
  font-size: 1rem;
}

.link-btn:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b1b3f8, 0 1px 2px 0 rgb(0, 0, 0);
}

.pr-input {
  width: 100% !important;
  height: 50px
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.opacity {
  opacity: 0.7;
}

.p-btn {
  background-color: #141B4D !important;
  color: #FFF !important;
  font-weight: bold !important;
  border-radius: 2rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  font-size: 1.1rem !important;
}

.btn-pill {
  border-radius: 2rem !important;

}

.p-contextmenu-root-list li a {
  text-decoration: none;
}

.loading .modal-content {
  background-color: transparent;
  width: auto;
  padding: 0.5rem;
  border: none;
}

.jscb {
  justify-content: space-between;
}

ul.p-dropdown-items {
  padding-left: 0px !important;
}

.link-icon-btn {
  background-color: #4F46E5;
  color: #fff;
  border-radius: 25px;
  align-items: center;
  display: flex;
  text-decoration: none;
  padding: 9px;

}

.outline-btn {
  border: 3px solid #141B4D !important;
  font-weight: bold !important;
  border-radius: 3rem !important;
}

.outline-btn:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b1b3f8, 0 1px 2px 0 rgb(0, 0, 0);
}

.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.search-input {
  border-radius: 3rem !important;
  border-width: 2px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.service-btn {
  background-color: transparent;
  border: none;
  padding: 0px;
  border-radius: 8px;
  color: #000;
}

.icon-sm-btn {
  padding: 4px !important;
  border-radius: 4rem;
}

.icon-xs-btn {
  padding: 3px;
  border: none;
  border-radius: 5rem;
}

.icon-xs-btn:focus {
  box-shadow: none;
  background-color: #FFF;
}

.jcc {
  justify-content: center;
}

.xs-input {
  height: 40px;
}

.xs-input input {
  width: 5.5rem;
}

.icon-xs-btn.bg:focus {
  background-color: red;
}

.rounded-btn {
  border-radius: 4rem !important;
}

ul {
  padding-left: 0px !important;
}

.p-multiselect-items {
  direction: initial !important;
}

.round-btn {
  border-radius: 3rem !important;
}

.pr-input.sm input {
  width: 100%;
}

.mobile-side-menu-offcanvas {
  background-color: #141B4D;
}

.mobile-side-menu-offcanvas .offcanvas-body,
.offcanvas-header {
  background-color: #141B4D;

}

.mobile-side-menu-offcanvas .offcanvas-body li a {
  text-decoration: none;
}

.fileinput-wrapper {
  border: 3px dotted #000;
  border-radius: 30;
  height: 250px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.attachments-preview {
  max-width: 100%;
  overflow: scroll;
}

.p-tabview-nav li a {
  text-decoration: none;
}

.p-galleria-thumbnail-wrapper {
  border-radius: 10px;
}

.aic {
  align-items: center;
}

.file-sliders {
  direction: initial;
}

.p-password div {
  width: 100% !important;
  height: 50px !important;
}

.p-password div input {
  width: 100% !important;
  height: 50px !important;
}

.active.card {
  border-color: #141B4D;
  border-width: 2px;
}

#root.ar td {
  text-align: right !important;
}

.fr-wrapper>div:first-child a {
  display: none !important;
}

.fr-view>p:last-child {
  display: none !important;
}
.fr-second-toolbar #fr-logo{
  display:  none !important;
}